var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuTemplate"},[(_vm.featuredScreen)?_c('div',[_c('featuredParent',{attrs:{"featuredScreen":_vm.featuredScreen}})],1):_vm._e(),_vm._l((_vm.categoryScreens),function(item,index){return _c('div',{key:index},[((item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled))?_c('continueSlider',{attrs:{"screen":item,"playerInstance":_vm.playerInstance}}):_vm._e(),(index == 0 && _vm.getUserType() != 'SUBSCRIBED' )?_c('div',{staticStyle:{"margin":"auto","text-align":"center"}},[_c('div',{staticStyle:{"min-width":"336px","min-height":"280px"},attrs:{"id":"div-gpt-ad-1724927568403-0"}})]):_vm._e(),(
          (item.sectionType !== 'CONTINUEWATCH' && item.sectionType !== 'CONTINUEWATCHING' && item.sectionType !== 'ADSLOT')  &&
            (item.isSectionEnabled || item.status == 'ACTIVE') &&
            (!item.userTypes ||
              (item.userTypes && item.userTypes.includes('ALL')) ||
              (item.userTypes && item.userTypes.includes(_vm.getUserType())))
        )?_c('slider',{attrs:{"screen":item,"id":index,"playerInstance":_vm.playerInstance,"screenName":_vm.screens.id}}):_vm._e(),(item.sectionType === 'ADSLOT' && (
              (item.userTypes && item.userTypes.includes('ALL')) ||
              (item.userTypes && item.userTypes.includes(_vm.getUserType()))) && item.status == 'ACTIVE' )?_c('div',{staticClass:"scrollerFooter",staticStyle:{"text-align":"center","margin":"1rem 0rem"}},[_c('ins',{staticClass:"adsbygoogle",staticStyle:{"display":"block"},attrs:{"data-ad-client":_vm.getClientId(item, 'clientad'),"data-ad-slot":_vm.getClientId(item, null),"data-ad-format":"auto","data-full-width-responsive":"true"}})]):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }